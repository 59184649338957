import Image from 'next/image';
import Link from 'next/link';

import { routes } from '@endaoment-frontend/routes';
import { Button, notfoundImage } from '@endaoment-frontend/ui/shared';

import { PageLayout } from '../components/PageLayout';

import styles from './404.module.scss';

const NotFoundPage = () => {
  return (
    <PageLayout>
      <div className={styles['not-found__container']}>
        <Image src={notfoundImage} width={400} alt='' priority />
        <h1 className={styles['not-found']}>Page Not Found!</h1>
        <Button as={Link} href={routes.marketing.home()} filled variation='purple'>
          Go Home
        </Button>
      </div>
    </PageLayout>
  );
};

export default NotFoundPage;
